<template>
    <div>
        <page-header
            title="Aktualności"
            variant="short"
            :image="require('./../assets/media/header_news.png')">
        </page-header>

        <section class="news decorated-top-white">
            <b-container>
                <b-row ref="filters" class="mb-5 filter-bar align-items-center align-items-lg-start" align-h="between">
                    <b-col cols="auto" order="1">
                        <tag-dropdown @change="addTagFilter" :tags="tags"></tag-dropdown>
                    </b-col>
                    <b-col order="3" order-lg="2" cols="12" lg>
                        <selected-tag-cloud :tags="filters.tags" :on-remove="removeTagFilter"></selected-tag-cloud>
                    </b-col>
                    <b-col order="2" order-lg="3" cols="auto">
                        <search-filter v-model="filters.search"></search-filter>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <entity-full-block-listing v-if="news.length > 0" :items="news.slice(page * 3, page * 3 + 3)"></entity-full-block-listing>
                      <div v-else class="text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" label="Loading Spinner"></b-spinner>
                      </div>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col class="text-right">
                        <pagination :number_of_pages="number_of_pages" :current_page="page" @change="i => { this.page = i; this.$refs.filters.scrollIntoView({ behavior: 'smooth' }) }"></pagination>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <newsletter></newsletter>
    </div>
</template>

<script>
    import PageHeader from "../components/utility/PageHeader";
    import EntityFullBlockListing from "../components/utility/EntityFullBlockListing";
    import Newsletter from "../components/blocks/Newsletter";
    import Pagination from "../components/utility/Pagination";
    import { tag_filter, search_filter } from '../components/mixins'
    import SelectedTagCloud from "../components/utility/SelectedTagCloud";
    import SearchFilter from "../components/utility/SearchFilter";
    import TagDropdown from "../components/utility/TagDropdown";
    export default {
        name: "News",
        components: {
            TagDropdown,
            SearchFilter, SelectedTagCloud, Pagination, Newsletter, EntityFullBlockListing, PageHeader},
        mixins: [tag_filter, search_filter],
        data() {
            return {
                page: 0,
                isActive: false,
            }
        },
        computed: {
            number_of_pages() {
                return Math.ceil(this.news.length / 3)
            },
            all_news() {
                return this.$store.state.content.news
            },
            news() {
                return this.all_news
                    .filter(n => this.filterUsingTag(n))
                    .filter(n => this.filterUsingSearch(n))
            }
        },
        mounted() {
            this.$store.dispatch('getAllNews')
        }
    }
</script>

<style scoped lang="scss">
    section.news {
        padding-top: 80px;
    }
</style>